import './_vendor';
import vars from './_vars';
import './_functions';
import './_components';
import './_inputmask';
import './_nice-select2';
import {NiceSelect} from "./_nice-select2";

//calc



if(document.querySelector('.select__current')) {
  let region = document.querySelector('.select__current')
  let regionList = document.querySelector('.select__list')
  let regionItems = regionList.querySelectorAll('.list__item');
  let depthMin = document.querySelector('.range__min')
  let depthMax = document.querySelector('.range__max')
  let depthMiddle = document.querySelector('.region__depth .depth__value')
  const pipes = document.querySelectorAll('.pipe__item');
  const extraOffers = document.querySelectorAll('.extra__list .list__item')
  let pricePipe = 2800, priceDepth = 20, priceExtra = 0, price = '101 000 ₽', mailResult = []

  let myRange = document.querySelector('.range__value');
  let myValue = document.querySelector('.range__number');
  mailResult['pipeItem'] = pipes[1].querySelector('.item__material').innerHTML.split('</div>\n')[0]
  mailResult['pipePnd'] = pipes[1].querySelector('.item__pnd').innerHTML.split('</div>\n')[0]
  mailResult['pipePrice'] = pipes[1].querySelector('.item__price').innerHTML.split('₽/М')[0]
  mailResult['region'] = region.innerHTML
  mailResult['range'] = myRange.valueAsNumber
  function setRange() {
    let thumbWidth = 25;

    let off = (myRange.clientWidth - thumbWidth) / (parseInt(myRange.max) - parseInt(myRange.min));
    let px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.clientWidth / 2) + (thumbWidth / 2);

    myValue.style.left = px + 'px';
    myValue.innerHTML = myRange.value;

    myRange.oninput = function() {
      let px = ((myRange.valueAsNumber - parseInt(myRange.min)) * off) - (myValue.clientWidth / 2) + (thumbWidth / 2);
      myValue.innerHTML = myRange.value;
      myValue.style.left = px + 'px';
      priceDepth = myRange.valueAsNumber
      mailResult['range'] = myRange.valueAsNumber
      setPrice()
    };
  }

  setRange();

  region.addEventListener('click', setRegion)

  function setRegion() {
    regionList.classList.toggle('active')
    region.classList.toggle('active')
  }

  pipes.forEach((item)=> {
    item.addEventListener('click', ()=> {
      pricePipe = item.getAttribute('data-price')
      pipes.forEach((item ) => { item.querySelector('label').classList.remove('active') } )
      item.querySelector('label').classList.add('active')
      console.log(item.querySelector('.item__material').innerHTML.split('</div>\n')[0])
      mailResult['pipeItem'] = item.querySelector('.item__material').innerHTML.split('</div>\n')[0]
      mailResult['pipePnd'] = item.querySelector('.item__pnd').innerHTML.split('</div>\n')[0]
      mailResult['pipePrice'] = item.querySelector('.item__price').innerHTML.split('₽/М')[0]
      setPrice()
    })
  })

  extraOffers.forEach((item)=> {
    item.addEventListener('click', ()=> {
      if(!item.querySelector('label').classList.contains('active')) {
        priceExtra = item.getAttribute('data-price')
        extraOffers.forEach((item ) => { item.querySelector('label').classList.remove('active') } )
        item.querySelector('label').classList.add('active')
        console.log(pricePipe + '     '+ ' ' + item.querySelector('label.active').previousElementSibling)
        mailResult['extra'] = item.getAttribute('data-price')
        mailResult['extraItem'] = item.querySelector('.item__name').innerHTML
        setPrice()
      } else {
        item.querySelector('label').classList.remove('active')
        mailResult['extra'] = ''
        mailResult['extraItem'] = ''
        priceExtra = 0
        setPrice()
      }
    })
  })


//region change
  regionItems.forEach((item)=> {
    item.addEventListener('click', ()=> {
      region.innerHTML = item.innerHTML
      mailResult['region'] = item.innerHTML
      // console.log(item.getAttribute('data-min'))
      // console.log(item.getAttribute('data-max'))
      // console.log(myRange.valueAsNumber)
      regionList.classList.remove('active')
      myRange.setAttribute('min', item.getAttribute('data-min'))
      myRange.setAttribute('max', item.getAttribute('data-max'))
      depthMin.innerHTML = item.getAttribute('data-min') + ' М'
      depthMax.innerHTML = item.getAttribute('data-max')  + ' М'
      depthMiddle.innerHTML = item.getAttribute('data-middle') + ' М';
      item.getAttribute('data-middle') != myRange.valueAsNumber ? myRange.valueAsNumber = item.getAttribute('data-middle') : null
      region.classList.remove('active')
      priceDepth = myRange.valueAsNumber
      setPrice()
      setRange()
      // pipes.forEach((item) => {
      //
      //   console.log(item.innerHTML.split('₽/М')[0].trim())
      // })
    })
  })

  function setPrice() {
    // console.log(pricePipe,priceDepth, priceExtra)
    console.log(mailResult)
    price = Number(pricePipe) * Number(priceDepth) + Number(priceExtra)
    document.getElementById('data-wa-form').value = `Материал: ${mailResult['pipeItem'] = mailResult['pipeItem'].replace(/<[\/]*span(.*?)>/g, ' ')}; ПНД: ${  mailResult['pipePnd'] = mailResult['pipePnd'].replace(/<[\/]*span(.*?)>/g, ' ')};Глубина: ${mailResult['range']}; Цена трубы: ${mailResult['pipePrice']}; Регион: ${mailResult['region']}; Регион: ${mailResult['region']}; Обустройство: ${mailResult['extraItem'] ? mailResult['extraItem'] : 'Не указано'}; Цена обустройства:${mailResult['extra'] ? mailResult['extra'] : 'Не указано'} ;`
    document.querySelector('.total__price').innerHTML = format(String(price)) + ' ₽'
  }

  function format(str) {
    const s = str.length;
    const chars = str.split('');
    const strWithSpaces = chars.reduceRight((acc, char, i) => {
      const spaceOrNothing = ((((s - i) % 3) === 0) ? ' ' : '');
      return (spaceOrNothing + char + acc);
    }, '');

    return ((strWithSpaces[0] === ' ') ? strWithSpaces.slice(1) : strWithSpaces);
  }

  function outNum(num) {
    let l = document.querySelector('.total__price')
    let n = 0;
    let t = Math.round(1000/(num/1000));
    let interval = setInterval(() => {
        n = n + 1000;
        if (n == num) {
          clearInterval(interval);
        }
        l.innerHTML = n ;
      },
      t);
  }


  setPrice()
}



const popBtn = document.querySelectorAll('.pop-btn');
const burger = document.querySelector('.header__burger')
const menu = document.querySelector('.wrapper--bottom')
const closePop = document.querySelector('.pop__close')
const pop = document.querySelector('.pop')
const phoneInput = document.querySelector('.form__tel')
const phoneInput2 = document.querySelector('.form__input-tel')
const phoneInput3 = document.querySelector('.calc__form .form__input')
const phoneInput4 = document.querySelector('.callus__form .input__tel')
const phoneInput5 = document.querySelector('.pop__input.input__tel')
let phoneInput6 = document.querySelector('.banner__form .input__tel')

burger.addEventListener('click', () => {
  burger.classList.toggle('active')
  menu.classList.toggle('active')
})


closePop.addEventListener('click', () => {
  pop.classList.remove('active');
})


popBtn.forEach((item)=> {
  item.addEventListener('click', ()=> {
    pop.classList.add('active');
  })})

const im = new Inputmask("+7(999)999-99-99");


phoneInput ? im.mask(phoneInput) : null
phoneInput2 ? im.mask(phoneInput2) : null
phoneInput3 ? im.mask(phoneInput3) : null
phoneInput4 ? im.mask(phoneInput4) : null
phoneInput5 ? im.mask(phoneInput5) : null
phoneInput6 ? im.mask(phoneInput6) : null

// im2.mask(phoneInput2);
// im3.mask(phoneInput3);
// im4.mask(phoneInput4);
// im5.mask(phoneInput5);
// im6.mask(phoneInput6);





const statistic = new Swiper('.statistic__info', {
  loop: true,
  slidesPerView: 1.6,
  spaceBetween: 20,  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },
  // slidesPerView: 'auto',
  breakpoints: {
    560: {
      slidesPerView: 3.2,
    },
    1024: {
      slidesPerView: 3.1,
    },
    1280: {
      slidesPerView: 4.1,
    },
    1700: {
      slidesPerView: 5.5,
    },
    2153: {
      slidesPerView: 7,
    }
  }
});
document.addEventListener('DOMContentLoaded', () => {
  const width = window.innerWidth
  if (width < 1279) {

  }
})
const news = new Swiper('.news__list', {
  slidesPerView: 1.5,
  loop: false,
  centeredSlides: false,
  enabled: true,
  spaceBetween: 30,  // If we need pagination
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    560: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 3,
    },
    1280: {
      slidesPerView: 4,
      enabled: false,
      centeredSlides: false,
    }
  }
});
const cert = new Swiper('.cert__list', {
  slidesPerView: 1.5,
  loop: false,
  enabled: true,
  spaceBetween: 30,  // If we need pagination
  breakpoints: {
    768: {
      slidesPerView: 2.5,
    },
    1280: {
      slidesPerView: 5,
    }
  }
});





